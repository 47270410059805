
@import '../../scss/styles.scss';
@import '../../App.scss';
.setFreePeriodsection {
  .setFreePeriodHeadSection {
    .setFreePeriodHead {
      color: #000000;
      font-size: 21.6px;
      font-weight: 500;
      line-height: 26px;
      background-color: white;
    }
  }
  .setFreePeriodFormSection {
    #setFreePeriodInput {
      width: 70px !important;
      height: 35px !important;
      outline: none;
      background-color: white !important;
      border: 0.92px solid #D4E5F9 !important;
      border-radius: 2.76px !important;
    }
    #dropdownMenuLink {
      border: 0.92px solid #d4e5f9;
      border-radius: 2.76px;
      width: 126px;
      height: 35.71px;
      text-align: left;
    }
    #dropdownMenu {
      min-width: 126px;
    }
    .dropdown-toggle::after {
      margin-left: 0;
      position: absolute;
      top: 14.5px;
      right: 10px;
    }
  }
  .setfreePeriodBtn {
    background-color: #4880ff;
    border-radius: 2.87px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.27);
    width: 129.6px;
    height: 46.8px;
    color: #fefffe;
    font-size: 14.4px;
    font-weight: 500;
    line-height: 17px;
    float: right;
  }
  .setfreePeriodBtn:hover {
    color: #fefffe;
  }
  .setfreePeriodBACKBtn {
    background-color: #fff;
    color: #bebebe;
    font-size: 14.4px;
    font-weight: 500;
    line-height: 17px;
    text-align: start;
    #backArrowSvg{
      padding-right: 10px;
    }
  }
  .setfreePeriodBACKBtn:hover {
    color: #bebebe;
  }
}
