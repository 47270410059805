.addBankModel {
  width: 366px;
  height: max-content;
  position: relative;
  #addBankmodelHeader {
    border-bottom: none;
  }
  .addBankDetails {
    .addBank-in-head {
      align-items: center;
      .addBank-in-Input {
        position: relative;
        .addbankfieldError {
          position: absolute;
          bottom: -20px;
        }
      }
    }
  }
  .addBAnkErrorInvalid {
    position: absolute;
    top: 350px;
  }

  input {
    font-size: 14.1px !important;
    // font-family: "latoRegular" !important;
    font-weight: 500 !important;
    border: none !important;
    border-radius: 5px !important;
    background: #e5e9ed !important;
    height: 33px !important;
  }

  .AddBankKeyBtn {
    width: 132px;
    height: 39px;
  }
}

.AddBackIntegrateBtn {
  background-color: #4880ff;
  border-radius: 2.4px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27);
  width: 112px;
  height: 39px;
}

.addNexusErrorInvalid {
  position: absolute;
  top: 130px;
}
