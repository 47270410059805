@import "../Dashboard/DashboardTable.scss";
@import "../../App.scss";

.en-li-searchView {
  #en-li-searchstyle {
    width: 100%;
  }
  input {
    background-color: #ffffff;
    border: 1px solid #d4e5f9;
    border-radius: 3px;
    width: 67px !important;
    height: 35px;
    color: #535353;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    border-right: none !important;
  }
  input:focus {
    border: 1px solid #d4e5f9 !important;
    border-right: none !important;
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  span {
    border: 1px solid #d4e5f9 !important;
    background-color: #fff !important;
  }
  img {
    width: 14.14px;
    height: 14.13px;
  }
}

.encompasstask-list-layout {
  .en-co-ActionTh {
    width: 33%;
    #enco-Lis-rejeBtn{
      color: #FF5660;
      background-color:transparent;
      border: none;
      outline:none;
      outline:0;
      pointer-events: none;
     text-align: start;
    }
    #enco-Lis-integrateBtn{
      color: #24ccb8;
      background-color:transparent;
      border: none;
      outline:none;
      outline:0;
      pointer-events: none;
      text-align: start;
    }
  }
  .en-co-20 {
    width: 18%;
    padding-right: 0px;
  }
  .en-co-0 {
    width: 18%;
    padding-right: 0;
    padding-left: 0;
  }
  .encompassText {
    color: #5e5e5e;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  tbody {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 20rem) !important;
    min-height: calc(100vh - 20rem) !important;

    td {
      word-wrap: break-word;

      .tableText {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        overflow: auto;
      }
      .sendBtn {
        //margin-right: 12px;
      }
      .sendBtn:hover {
        background-color: #24ccb8;
        color: #fff;
      }
      .rejBtn:hover {
        background-color: #ff5660;
        color: #fff;
      }
    }
    #tableBodyRow {
      background-color: #fff;
    }
    button {
      background-color: #eff5fc;
      border: 1px solid #d4e5f9;
      border-radius: 3px;
      width: 103px;
      height: 39px;
    }
    .approveBtn:hover {
      background-color: #24ccb8;
      color: #fff;
    }
    .rejectBtn:hover {
      background-color: #ff5660;
      color: #fff;
    }
  }
}

td {
  padding-bottom: 15px !important;
}

.table-layout {
  overflow-y: hidden !important;
  thead input {
    background-color: #ffffff;
    border: 1px solid #d4e5f9;
    border-radius: 3px;
    width: 120px;
    height: 35px;
  }
  #encompassHeadRow .tableHeadText {
    color: #5e5e5e;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  .p-19 {
    padding-bottom: 19px;
  }
  thead .encompasssearch {
    background-color: #ffffff;
    border: 1px solid #d4e5f9;
    border-radius: 3px;
    width: 100px;
    height: 35px;

    span {
      background-color: #fff;
      border: none;
    }
    input {
      border: none;
      color: #4a494a;

      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      outline: none;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }
}

.right-btns {
  .btn {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27) !important;
    height: 40px;
    font-family: "helveticaMedium";
    width: 150px;
  }
}

.clearbtn {
  height: 40px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}
