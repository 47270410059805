.selectplan7section{
    .selectplanHeadSection{
        .selectplanHead{
            color: #000000;
            font-size: 21.6px;
            font-weight: 500;
            line-height: 26px;
        }
    }
    .selectPlanFormSection{
        .checkboxSection{
        }
        .checkboxlabel {
            display: block;
            position: relative;
            padding-left: 25px;
            cursor: pointer;
            font-size: 14px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            margin-bottom: 0;
            color: #000000;
            font-weight: 400;
            line-height: 30px;


          }
          
          /* Hide the browser's default radio button */
          .checkboxlabel input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          
          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 7px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: #fff;
            border:1px solid black;
            border-radius: 50%;
          }
          
          /* On mouse-over, add a grey background color */
          
          
          /* When the radio button is checked, add a blue background */
          .checkboxlabel input:checked ~ .checkmark {
            background-color: #fff;
          }
          
          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          
          /* Show the indicator (dot/circle) when checked */
          .checkboxlabel input:checked ~ .checkmark:after {
            display: block;
          }
          
          /* Style the indicator (dot/circle) */
          .checkboxlabel .checkmark:after {
               top: 3px;
              left: 3px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #4880FF;
              border:1px solid black;
          }



        
    }
}