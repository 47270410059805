@import '../../App.scss';
.dashboard {
  color: $color-white;
  .sidenavbar {
    background-color: $color-white;
    @media screen and (min-width: 960px) {
      flex: 0 0 5.333333%;
      max-width: 5.333333%;
    }
  }

  .dashboard-layout {
    // height: -webkit-fill-available;
    max-height: 100%;
    .router-text {
      font-family: $hn-bold;
      font-size: 18px;
      position: relative;
      top: 0.7rem;
    }
    .usr-text {
      font-family: $lato-bold;
      font-size: 14px;
    }
  }

  .col-sm-11 {
    @media screen and (min-width: 960px) {
      flex: 0 0 94.666667%;
      max-width: 94.666667%;
    }
  }

  .header-bg {
    height: 165px;
    background: #4880ff url(../../assets/images/header_bg.png) right no-repeat;
    background-position-y: 0px;
    background-position-x: 0px;
    background-blend-mode: soft-light;
    background-size: cover;
    .profile-pic {
      width: 50px;
      height: 50px;
      border: 2px solid #fff;
    }

    .text-small {
      font-size: 10px;
      font-family: $lato-regular;
    }
  }

  .wrapper {
    margin: -4em 3em 0 3em;
    height: calc(100% - 90px);
  }
}
.text-uppercase {
  height: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}
.settings {
  .header-bg {
    height: 90px;
  }
  .wrapper {
    margin: 0px;
  }
}