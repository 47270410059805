.side-bar-menu {
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  .column {
    display: flex;
    flex-direction: column;
    height: auto;
    .logo-img {
      width: 60px;
      height: 60px;
    }

    .menu-box {
      border-right: 2px solid #fff;
      color: #9b9b9b;
      cursor: pointer;
      height: 30px;
      &:hover {
        border-right: 2px solid #4880ff;
        color: #4880ff;
        text-decoration: none;
        width: 100%;
      }
      a {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .active {
      border-right: 2px solid #4880ff;
      color: #4880ff;
      width: 100%;
    }
  }
}

.bs-tooltip-auto[x-placement^="right"] span.arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #4a7efa;
}
