#re-fa-tableHeadRow{
    .re-fa-table-headText{
        color: #535353;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        vertical-align: bottom;
        border-bottom: none;
    }
}
#re-fa-tableBodyRow{
    .re-fa-tableText{
        color: #000;
        font-size: 12px;
       font-weight: 500;
        line-height: 17px;
        overflow: auto;
        .re-fa-tableEmailText{
            color: #000000;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            opacity: 0.699999988079071;
            text-align: left;
        }
    }
    .re-fa-addBtn:hover{
        background-color: #24ccb8;
      color: #fff;
    }
    .re-fa-stopBtn:hover{
        background-color: #ff5660;
        color: #fff;
    }
}
.re-fa-actionTh{
    width: 28%;

}