.us-li-section {
  .us-li-headText {
    color: #5e5e5e;

    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .us-li-searchView {
    #us-li-searchstyle {
      width: 100%;
    }
    input {
      background-color: #ffffff;
      border: 1px solid #d4e5f9;
      border-radius: 3px;
      width: 206px;
      height: 35px;
      color: #535353;
      // font-family: HelveticaNeue;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      border-right: none;
    }
    input:focus {
      border: 1px solid #d4e5f9;
      border-right: none;
      outline: none !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    }
    span {
      border: 1px solid #d4e5f9;
      background-color: #fff;
    }
    img {
      width: 14.14px;
      height: 14.13px;
    }
  }
  #us-li-tableHeadRow {
    .us-li-table-headText {
      color: #535353;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
      vertical-align: bottom;
      border-bottom: none;
    }
  }
  #us-li-tableBodyRow {
    .us-li-tableText {
      color: #000;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      overflow: auto;
    }
    .us-li-deActiveBtn:hover {
      background-color: #ff5660;
      color: #fff;
    }
    .us-li-activeBtn:hover {
      background-color: #24ccb8;
      color: #fff;
    }
  }
}
