#ap-fa-tableHeadRow{
        .ap-fa-table-headText{
            color: #535353;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            text-align: left;
            vertical-align: bottom;
            border-bottom: none;
        }
}
    #ap-fa-tableBodyRow{
        .ap-fa-tableText{
            color: #000;
            font-size: 12px;
           font-weight: 500;
            line-height: 17px;
            overflow: auto;
            .ap-fa-tableEmailText{
                color: #000000;
                font-size: 10px;
                font-weight: 400;
                line-height: 12px;
                opacity: 0.699999988079071;
                text-align: left;
            }
        }
        .ap-fa-addBtn:hover{
            background-color: #24ccb8;
          color: #fff;
        }
        .ap-fa-stopBtn:hover{
            background-color: #ff5660;
            color: #fff;
        }
    }
    .ap-fa-actionTh{
        width: 27%;
    
}