#ap-fp-tableHeadRow{
    .ap-fp-table-headText{
        color: #535353;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-align: left;
        vertical-align: bottom;
        border-bottom: none;
    }
}
#ap-fp-tableBodyRow{
    .ap-fp-tableText{
        color: #000;
        font-size: 12px;
       font-weight: 500;
        line-height: 17px;
        overflow: auto;
        .ap-fp-tableEmailText{
            color: #000000;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            opacity: 0.699999988079071;
            text-align: left;
        }
    }
    .ap-fp-addBtn:hover{
        background-color: #24ccb8;
      color: #fff;
    }
    .ap-fp-stopBtn:hover{
        background-color: #ff5660;
        color: #fff;
    }
}
.ap-fp-actionTh{
    @media (max-width: 768px) {
        width: 23%;
    }
    @media (min-width: 768px) {
        width: 27%;
    }

}


