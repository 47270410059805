/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import '../scss/variables.scss';
// @import '../assets/css/quill-mention.css';



$text-common-color: #4a4a4a;
$input-border-color: #d4e5f9;

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6f82ad28;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 7px;
  background: #8ba1d4;
}

.outline {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important;
  outline: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #f2f2f2 !important;
  box-sizing: border-box;
  // font-family: 'HelveticaNeue', 'Lato', sans-serif !important;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url(../../assets/fonts/HelveticaNeue.ttf);
}

@font-face {
  font-family: 'helveticaBold';
  src: url(../../assets/fonts/HelveticaNeuBold.woff);
}

@font-face {
  font-family: 'helveticaMedium';
  src: url(../../assets/fonts/HelveticaNeuMedium.woff);
}

@font-face {
  font-family: 'helveticaLight';
  src: url(../../assets/fonts/HelveticaNeuLight.woff);
}
@font-face {
  font-family: 'helveticaMedium';
  src: url(../../assets/fonts/helveticaneue-medium.ttf);
}
@font-face {
  font-family: 'latoBold';
  src: url(../../assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: 'latoHeavy';
  src: url(../../assets/fonts/Lato-Heavy.ttf);
}

@font-face {
  font-family: 'latoLight';
  src: url(../../assets/fonts/Lato-Light.ttf);
}
@font-face {
  font-family: 'latoRegular';
  src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'latoSemiBold';
  src: url(../../assets/fonts/Lato-Semibold.ttf);
}

@font-face {
  font-family: 'latoThin';
  src: url(../../assets/fonts/Lato-Thin.ttf);
}
@font-face {
  font-family: 'latoMedium';
  src: url(../../assets/fonts/Lato-Medium.ttf);
}
.color-black-text {
  color: #000;
}

.color-grey-text {
  color: $text-common-color;
}
.color-black-light-grey {
  color: $color-black-light-grey;
}

.text-color-sucess{
  color: #24CCB8;
}
.text-color-Notsucess{
  color: #FF5660;
}

.color-lght-grey {
  color: #cccccc;
}
.color-grey-black {
  color: #4a494a;
}

.icon-color {
  color: #a5a5a5;
}

.bg-color {
  background: #f2f2f2;
}

.bg-white {
  background: $color-white;
}

.bg-light-blue {
  background: #eff5fc !important;
}

.btn-sea-green {
  background: $color-light-sea-green !important;
}
.btn-primary {
  background-color: $color-primary !important;
  border-color: $color-primary !important;

  &:focus {
    @extend .outline;
  }
}

.btn {
  &:focus {
    @extend .outline;
  }
}

.btn-light {
  color: $text-common-color !important;
  background-color: $color-white !important;
  border-color: #d4e5f9 !important;

  &:hover {
    color: $text-common-color !important;
    background-color: $color-white !important;
    border-color: #d4e5f9 !important;
  }

  &:focus {
    @extend .outline;
  }
}
.shadow {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27) !important;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27) !important;
}
.adduserPopupContiner {
  display: none !important;
  .modal-content {
    top: 10vh;
  }
}

.btn-danger {
  background-color: $color-danger !important;
  border-color: $color-danger !important;

  &:focus {
    @extend .outline;
  }
}

// pagination CSS
.page-link {
  border: none !important;
  color: #9e9e9e !important;
  font-size: 14px;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 247, 247, 0.25) !important;
  }
  &:hover {
    font-size: 14px;
    color: $color-black !important;
    background: transparent !important;
  }
}

.page-item.active {
  .page-link {
    color: $color-white !important;
    background-color: #4880ff !important;
    font-size: 15px !important;
    border-color: $color-white !important;
    border-radius: 50%;
  }
}

.page-item.disabled a span {
  color: #00416a !important;
}

// Dropdown CSS
.custom-select {
  height: 32px !important;
  font-size: 12px !important;
  color: #787e8c !important;
  background-color: $color-white !important;
  background: url(https://cdn3.iconfinder.com/data/icons/unicons-vector-icons-pack/32/select-bottom-128.png) no-repeat
    right 0.75rem center/15px 15px !important;
  border: 1px solid $input-border-color !important;
  border-radius: 0 !important;

  &:focus {
    @extend .outline;
  }
}

.contact-layout {
  .custom-select {
    font-size: 12px !important;
    color: #8e8e8e !important;
    border: none !important;
    width: 160px !important;
    position: relative;
    bottom: 10px;
    bottom: 7px;
    font-weight: 500 !important;
  }
}
.signup {
  .sign-select {
    background-color: #e5e9ed !important;
    font-size: 16.66px !important;
    padding-left: 25px !important;
    height: 52px !important;
  }
}
.task-list-layout {
  .task-table-dropdown {
    .custom-select {
      font-size: 12px !important;
      color: #4a4a4a !important;
      border: none !important;
      width: 120px !important;
      position: relative;
      bottom: 10px;
      bottom: 7px;
      font-weight: 500 !important;
    }
  }
}

// Button
button:focus {
 // outline: 1px auto white !important;
}

.btn {
  border-radius: 2px !important;
}

// Input
.form-control {
  color: $text-common-color !important;
  border-color: $input-border-color !important;
  box-shadow: 0 0 0 0.2rem transparent !important;
  border-radius: 1px !important;
  font-size: 12px !important;
  font-family: $lato-regular;

  &:focus {
    color: $text-common-color !important;
    border-color: $input-border-color !important;
    box-shadow: 0 0 0 0.2rem transparent !important;
    border-radius: 1px !important;
    font-size: 12px !important;
  }
}

.input-group-text {
  color: $text-common-color !important;
  background-color: transparent !important;
  border: 1px solid $input-border-color !important;
  border-radius: 1px !important;
  border-left-color: transparent !important;
}

input {
  &:focus {
    @extend .outline;
  }
}

.payment-layout {
  .input-group-text {
    color: $text-common-color !important;
    background-color: $color-grayshade !important;
    border: 1px solid $color-grayshade !important;
    border-radius: 5px !important;
    border-left-color: transparent !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

// Placeholder CSS for Browsers
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

//  Font Size
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}
.font-32 {
  font-size: 32px;
}
.font-11 {
  font-size: 11px !important;
}
.font-hn-medium {
  font-family: $hn-medium;
}
.font-hn-light {
  font-family: $hn-light;
}
.font-hn-bold {
  font-family: $hn-bold;
}
.font-lato-bold {
  font-family: $lato-bold;
}
.font-lato-medium {
  font-family: $lato-medium;
}
.font-lato-light {
  font-family: $lato-light;
}
.font-lato-regular {
  font-family: $lato-regular;
}

// Width CSS
.w-15 {
  width: 15%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-45 {
  width: 45%;
}
.w-65 {
  width: 65%;
}
// Height
.h-90 {
  height: 90% !important;
}
.h-40 {
  height: 40px;
}
.h-30 {
  height: 30px;
}
// Badge CSS
.badge-light {
  color: $color-black !important;
  background-color: #f0f0f0 !important;
}
// cursor
.cursor-pointer {
  cursor: pointer;
}
.bg-custom {
  background-color: #24ccb8 !important;
}
.bg-custom1 {
  background-color: #ff5660 !important;
}
.bg-custom2 {
  background-color: #ffc400 !important;
}
.bg-custom3 {
  background-color: #a3a3a3 !important;
}
.message-chat-popup {
  button:focus {
    outline: none !important;
  }
}

.input-layout {
  .form-control {
    font-size: 13px !important;
    background: $color-grayshade !important;
    height: 45px !important;
    font-weight: 400;
    border: none !important;
    border-radius: 5px !important;
  }

  .custom-select {
    height: 45px !important;
    font-size: 16.67px !important;

    background-color: $color-grayshade !important;
    border: none !important;
    border-radius: 5px !important;

    &:focus {
      @extend .outline;
    }
  }
  .add-select {
    font-size: 14.52px !important;
    font-family: $lato-regular !important;
    font-weight: 500 !important;
    &:focus {
      @extend .outline;
    }
  }
  .input-form-popup {
    font-size: 14.52px !important;
    font-family: $lato-regular !important;
    font-weight: 500 !important;
    &::placeholder {
      font-size: 14.52px !important;
      font-family: $lato-regular !important;
      font-weight: 500 !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn:focus {
  // box-shadow: none !important;
  outline: none !important;
}

// Table CSS

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05) !important;
  &:hover {
    background-color: #eff5fc !important;
  }
}
tbody {
  tr:nth-child(even) {
    background: #fbfdff !important;
  }
  tr:hover {
    background-color: #eff5fc !important;
  }
}
table thead th{
  border-bottom: none !important;
}
table tbody td{
  border-bottom: 1px solid #EFF5FC;
}

#preventTRBorder{

}

preventTdBorder{
  border-top: none;
}
.alignItemCenter{
  align-items: center;
}
#btnWithOutBorder{
  background-color: transparent;
  border: none;
  outline: none;
  outline: 0;
  pointer-events: none;
}

/***************************QUILL*******************************/

.quill{
  .ql-container{
    .ql-editor{
      min-height: 130px;
    }
  }
} 

#aiBirthdayMMS{
  .quill{
    border: 1px solid #ccc;
    display: flex !important;
    .ql-toolbar{
      order: 1 !important;
      border: none !important;
      margin-top: auto !important;
      margin-bottom: auto !important;
      button{
        // border: 1px solid blue !important; 
        height: 61px !important;
        width: 99px !important;
        position: relative !important;
        svg{
          display: none !important;
           & :after{
            content: '';

          }
        }
      }
      button::after{
        content:'';
        background:blue url(../../assets/images/Add_File.png) !important;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 61px !important;
        width: 99px !important;
      }
    }
    .ql-container{
      border: none !important;
      .ql-editor{
        display: flex !important;
      }
    }
    
  }
}

#aiAnniversryMMS{
  .quill{
    .ql-toolbar{
      button{
        // border: 1px solid blue !important; 
        height: 61px !important;
        width: 99px !important;
        position: relative !important;
        svg{
          display: none !important;
           & :after{
            content: '';

          }
        }
      }
      button::after{
        content:'';
        background:blue url(../../assets/images/Add_File.png) !important;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 61px !important;
        width: 99px !important;
      }
    }
  }
}
.mergefieldBtn::after{
display: none !important;
}


.flex-end{
  justify-content: flex-end !important;
}

/*   SET DATE NURTURE */

.set-nurture{
  .react-datepicker__input-container{
    input{
      width: 282px !important;
    }
  }
}

#confirmationConfirmBody{
  padding-top: 1rem!important;
  .justify-content-between{
    padding-top: 3rem!important;
  }
}

.object-key-val{
  span{
    display: initial !important;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mark, mark {
  padding: .2em;
  background-color: #ffd704 !important;
}
.currentMark{
  background-color: #ff8100 !important;
}
