@import "../../App.scss";
span {
  display: block;
}
.text-style {
  font-size: 14.3px;
  text-decoration: underline;
}
.right {
  padding: 0 2.5em 1rem 2.5rem;
  .card-body-heading {
    h3 {
      font-size: 36px;
      margin-bottom: 0;
    }
  }
  .form-control {
    font-size: 16.66px !important;
  }
  input {
    border-color: $color-grayshade !important;
    &::placeholder {
      font-size: 16.77px;
    }
  }
}
.showpass {
  color: #787e8c;
  top: 13px;
  right: 10px;
}

button span {
  width: 3rem;
  height: 3rem;
}
