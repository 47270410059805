@import "../Dashboard/DashboardTable.scss";
@import "../../App.scss";
@import "../../scss/styles.scss";
td {
  padding-bottom: 15px !important;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
}

#preventTRBorder {
  border-top: none !important;
  background-color: #fff !important;
}

#preventTdBorder {
  border-top: none !important;
  border-bottom: none !important;
  color: #989898;
}
.en-de-head-Text{
  color: #5E5E5E;
  
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left
}
.en-de-searchView {
  #en-de-searchstyle {
    width: 60%;
  }
  input {
    background-color: #ffffff;
    border: 1px solid #d4e5f9;
    border-radius: 3px;
    width: 206px;
    height: 35px;
    color: #535353;
    // font-family: HelveticaNeue;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    border-right: none;
  }
  input:focus {
    border: 1px solid #d4e5f9;
    border-right: none;
    outline: none !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  span {
    border: 1px solid #d4e5f9;
    background-color: #fff;
  }
  img {
    width: 14.14px;
    height: 14.13px;
  }
}

.en-de-table-layout {
  overflow-y: hidden !important;
  .actionTh {
    width: 25%;
  }

  tbody {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 20rem) !important;
    min-height: calc(100vh - 20rem) !important;

    td {
      word-wrap: break-word;

      .en-de-tableText {
        color: #000;
        // font-family: HelveticaNeue;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
      }
      .en-de-lastTd {
        position: relative;
      }
      #en-de-lastImage {
        position: absolute;
        width: 20px;
        height: 15px;
        top: 0;
      }
      .en-de-svgDiv {
        background-color: #eff5fc;
        margin-top: -4px;
        svg {
          width: 25px;
          height: 25px;
          border: 0.8px solid #d4e5f9;

          #ADMIN_INTEGRATION {
            fill: #8e8e8e;
          }
          #svgBack {
            fill: #fff;
          }
        }
      }
      #visible-bg-set {
        background-color: #4880ff;
        color: #fff;
      }
    }
    #en-de-tableBodyRow {
      background-color: #fff;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }
}

.en-de-table-striped {
  .en-de-table-header {
    background-color: #eff5fc;
  }
  #en-de-tableHeadRow {
    th {
      img {
        width: 11px;
        min-height: auto;
      }
    }
  }
  .en-de-HeadText {
    color: #535353;
    // font-family: HelveticaNeue;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    vertical-align: bottom;
    border-bottom: none;
    //border-top: none;
  }
  tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0);
  }
}

#en-de-clientDeatailsImg {
  width: 20px;
  height: 15px;
}

.en-de-clientHeadView {
  .en-de-clientHead {
    .en-de-profileName {
      color: #000000;
      // font-family: HelveticaNeue;
      font-size: 22px;
      font-weight: 500;
      line-height: 26px;
    }
    .en-de-profilePhone {
      color: #000000;
      // font-family: HelveticaNeue;
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .en-de-clientdetails {
    #en-de-secretOver {
      overflow: auto;
    }
    .en-de-clientText {
      color: #000000;
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
    }
    .en-de-clientEncText {
      color: #696969;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .en-de-clientAlign {
      text-align: end;
    }
    .en-de-subscribe {
      background-color: #eff5fc;
      color: #000000;
      font-weight: 500;
      line-height: 19px;
    }
  }
}
