@import "../../App.scss";

.secretModel {
  width: 356px;
  height: 323px;
  .secretKeyPossition{
    position: relative;
    .errormesaa{
      position: absolute;
      bottom: -20px;
      left: 10px;
    }
  }
  #SecretmodelHeader {
    border-bottom: none;
  }
    .secretKeyInput {
      width: 304px;
      height: 43px;

      input {
        width: 100%;
        height: 100%;
        background-color: #e5e9ed;
        border-radius: 5.5px;
      }
    }
    .secreteKeyBtn{
      width: 132px;
      height: 39px;
    }
  }

.secretIntegrateBtn {
  background-color: #4880ff;
  border-radius: 2.4px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27);
  width: 112px;
  height: 39px;
}
