// Font Family
$lato-heavy: 'latoHeavy';
$lato-light: 'latoLight';
$lato-bold: 'latoBold';
$lato-semibold: 'latoSemiBold';
$lato-regular: 'latoRegular';
$lato-medium: 'latoMedium';
$hn-bold: 'helveticaBold';
$hn-medium: 'helveticaMedium';
$hn-light: 'helveticaLight';

//  Font Weight
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 700;
$extra-bold: 800;

// Colors
$text-grey: #787e8c;
$color-white: #fff;
$color-primary: #4880ff;
$color-danger: #ff5660;
$color-grayshade: #e5e9ed;
$color-black-grey: #444444;
$color-black-light-grey: #4a494a;
$color-black: #000;
$color-light-sea-green: #24ccb8;
