@import '../../App.scss';


.selectPlanAll{
    width: 607px;
    height: 265.2px;
    #selectPlanmodelHeader{
        border-bottom: none;
    }
    #selectPlanBody{
        padding: 0;
        position: relative;
        .selectPlanUserDetails{
            background-color: #4880FF;
            height: 265.2px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            
                ul{
                    padding:0px,0px,0px,36px;
                }
                li{
                    list-style: none;
                }
                .userDataShow{
                    color: #FFFFFF;
                   // font-family: Lato;
                    font-size: 14.4px;
                    font-weight: 500;
                    line-height: 25.2px;
                }
                .selectPlanLicenseAmt{
                    color: #FAFF00;
                    font-size: 16.8px;
                    font-weight: 600;
                    line-height: 25.2px;
                    position: absolute;
                    bottom: 10px;
                }
        }
        .username{
            color: #FFFFFF;
          //  font-family: Lato;
            font-size: 21.4px;
            font-weight: 700;
            line-height: 32px;
            text-align: left;
        }
        
    }
}





// #footer {
//   background-color: #ffffff00 !important;
//   width: 50%;

//   input {
//     width: 45%;
//     font-size: 14.52px;
//     font-family: $lato-regular;
//     height: 40px;
//     &::placeholder {
//       font-family: $lato-regular;
//       font-weight: 500;
//       font-size: 14.52px;
//       color: #959595;
//     }
//   }
// }
.heightscroll {
  max-height: 230px;
  overflow: auto;
}
// table {
//   .th-width {
//     width: 20px;
//   }
// }

.inputLabelTest{
    color: #000000;
  font-family: Lato;
  font-size: 14.3px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
}


.styles_modal__gNwvD{
padding: 0;
}