@import '../../App.scss';


.partner-layout {
  border-radius: 5px;

  .filter-btn {
    width: 130px;
    height: 35px !important;
    font-family: $lato-regular;
  }

  .search-input {
    input {
      height: 35px !important;
      border-right-color: transparent !important;
      font-family: $lato-regular;
      font-size: 12px;

      &::placeholder {
        font-family: $lato-regular;
        font-size: 12px;
      }
    }

    i {
      font-size: 18px;
    }
  }

  .right-btns {
    button {
      height: 40px;
      font-family: $hn-medium;
      width: 150px;

      i {
        font-size: 18px;
        position: relative;
        top: 3px;
      }
    }

    .btn-delete {
      padding: 3px 6px 3px 6px;
    }
  }

  .table-layout {
    max-height: calc(100vh - 230px) !important;
    min-height: calc(100vh - 230px) !important;

    overflow-y: scroll;

    .table-header {
      background: #eff5fc;
      color: #4a4a4a;
      font-size: 14px;
      // th {
      //   cursor: pointer;
      // }
    }

    .table-body {
      color: #8e8e8e;

      .list-tr {
       // border-bottom: 1px solid #eff5fc !important;

        .data-align {
          padding-bottom: 0;
          font-family: $hn-light;
          font-weight: 700;
          color: #4a4a4a;
        }
      }

      img {
        width: 30px;
        height: 30px;
        position: relative;
        bottom: 6px;
        right: 4px;
      }

      .name {
        position: relative;
        bottom: 5px;
        right: 5px;
        color: #4a4a4a;
      }

      .data-align {
        position: relative;
      }
      // td{
      //   cursor: pointer;
      // }
    }

    .check-box {
      width: 25px;
    }

    .last-th {
      width: 200px;

      .icons {
        img {
          width: 15px;
        }
        span {
          cursor: pointer;

          i {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.email-width {
  width: 25%;
}

.table-layout {
  overflow-y: hidden !important;

  .checkbox {
    width: 30px;
    padding-right: 0 !important;
  }

  tbody {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 20rem) !important;
    min-height: calc(100vh - 20rem) !important;

    td {
      word-wrap: break-word;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
  }
}
#loadingProgressG {
  width: 32px;
  height: 2px;
  overflow: hidden;
  background-color: rgb(195, 219, 247);
  // margin: auto;
  border-radius: 1px;
  -o-border-radius: 1px;
  -ms-border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
}

.loadingProgressG {
  background-color: rgba(255, 255, 255, 0.98);
  margin-top: 0;
  margin-left: -32px;
  animation-name: bounce_loadingProgressG;
  -o-animation-name: bounce_loadingProgressG;
  -ms-animation-name: bounce_loadingProgressG;
  -webkit-animation-name: bounce_loadingProgressG;
  -moz-animation-name: bounce_loadingProgressG;
  animation-duration: 1.105s;
  -o-animation-duration: 1.105s;
  -ms-animation-duration: 1.105s;
  -webkit-animation-duration: 1.105s;
  -moz-animation-duration: 1.105s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -o-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  width: 32px;
  height: 2px;
}

@keyframes bounce_loadingProgressG {
  0% {
    margin-left: -32px;
  }

  100% {
    margin-left: 32px;
  }
}

@-o-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -32px;
  }

  100% {
    margin-left: 32px;
  }
}

@-ms-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -32px;
  }

  100% {
    margin-left: 32px;
  }
}

@-webkit-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -32px;
  }

  100% {
    margin-left: 32px;
  }
}

@-moz-keyframes bounce_loadingProgressG {
  0% {
    margin-left: -32px;
  }

  100% {
    margin-left: 32px;
  }
}
.right-btns {
  .btn {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27) !important;
  }
}