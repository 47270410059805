.loandetailModal{
    height: max-content;
    position: relative;
    .btn-normal{
        border: 1.2px solid #D4E5F9;
    }
    .jsonBtn{
        background-color: #EFF5FC !important;
        border: 1px solid #D4E5F9 !important;
        border-radius: 3px !important;
        cursor: pointer !important;
    }
    .border-div{
        border: 0.97px solid #C2C7D3;
      border-radius: 5.5px;
    }
    .form-control[readonly]{
        background-color: #fff !important;
    }
    .modal-scroll{
        height: 70vh;
        overflow-y: auto;
    }
}
.jsonPopUp{
    position: relative;
    z-index: 1;
    .jsonPopUpHead{
        background-color: #4880FF;
        
        width: 427px;
        height: 60px;
        // z-index: 1;
        
        .input-group{
            background-color: #fff;
            border: 1.1px solid #FFFFFF;
            input{
                border: 1.1px solid #FFFFFF;
                border-radius: 2.2px;
                width: 224px;
                height: 30px;
                border: 1px solid #fff !important;
            }
            img{
                width: 20.14px;
                height: 20.13px;
            }
        }
        
    }
    .jsonPoupContent{
        overflow: auto;
        width: 427px;
        height: 450px;
    }
    .closebtn{
        width: 25.14px;
        height: 24.13px;
    }
    .jsonPopClose{
        border-radius: 0 6.59px 0 0;
        border-top-left-radius: 8px;
        background-color: #4880FF;
    }
    
}
#chips {
    background: #e4e4e4;
    border-radius: 4px !important;
    padding: .375rem .75rem !important;
    border: 0.97px solid #C2C7D3;
    width: 100%;
    position: relative;
    .chipsClose-box {
        position: absolute;
        top: 0;
        right: 5px;
        color: #000;
        cursor: pointer;
    }
  }
  .confirmDefaultPopUp{
      min-width: 500px;
      .confirmContent{
          max-height: 350px; 
          overflow-y: auto;
      }
  }

