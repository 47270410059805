@import './scss/variables.scss';
@import './scss/styles.scss';



.card-body {
  height: 100vh;
  background-color: $color-grayshade;
  font-family: $lato-regular;
  .row {
    // height: 100%;
    border-radius: 6px;
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);
    @media screen and (min-width: 769px) {
      width: 70%;
    }
    .left {
      background-color: $color-primary;
      display: flex;
      align-items: center;
      &:after {
        content: '';
         background: url('../../assets/images/logo_white.png') no-repeat center center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-size: 95%;
        opacity: 0.06;
      }
      h1 {
        color: $color-white;
        font-family: $lato-heavy;
        font-weight: 800;
        font-size: 36px;
      }
      .text {
        font-size: 36px;
        color: $color-white;
        font-family: $lato-heavy;
        line-height: 40px;
      }
    }
    .right {
      background-color: $color-white;
      z-index: 999;
      border-radius: 0px 6px 6px 0px;
      &::after {
        content: '';
        background: url('../../assets/images/onboarding.png');
        background-repeat: no-repeat;
        background-position: top right;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 80%;
        z-index: -1;
      }
      .login {
        width: 100%;
      }
      .form {
        text-align: center;
        width: 100%;
      }
      .card-body-heading h2 {
        color: $color-black-grey;
        font-weight: 400;
        line-height: 22px;
        font-size: 36px;
      }
      .card-body-heading h3 {
        font-family: $lato-heavy;
        font-weight: $extra-bold;
      }
      .card-body-heading p {
        color: $color-black-grey;
        font-family: $lato-regular;
        font-size: 18px;
      }
      .card-body-footer a {
        font-family: Lato;
        font-size: 14.3px;
        font-weight: 400;
        line-height: 17px;
      }
      input,
      select {
        background-color: $color-grayshade;
        border-radius: 2.4px;
        padding: 25px;
        &:focus {
          outline: none;
        }
      }
      select {
        padding: 0px;
        padding-left: 15px;
        height: 53px;
        color: #787e8c !important;
      }
      input[type='submit'] {
        background-color: $color-primary;
        border: 0px;
        color: $color-white;
        border-radius: 2.4px;
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27);
        margin-top: 30px;
        margin-bottom: 5px;
        font-family: $lato-heavy;
        font-size: 17.67px;
        letter-spacing: 1.2px;
        font-weight: 800;
        width: 100%;
        padding: 15px;
      }
      input[type='submit']:hover {
        cursor: pointer;
      }
    }
  }
}

.color-grey {
  color: $text-grey;
}
.custom-control-label{
  vertical-align: baseline !important;
}
.custom-control-label::before{
  background-color: #007bff !important;
  top: 0 !important;
}
.custom-control-label::after{
  background-color: #fff !important;
  top: calc(0.0rem + 2px) !important;
}