#aianniverseryView{
    button{
         background-color:#fff;
         border: 1px solid #D4E5F9;
        border-radius: 3px;
        // //width: 112px;
         height: 35px;
         color: #4A494A;
         font-size: 14px;
         font-weight: 600;
         line-height: 17px;
         text-align: center;
         &:active{
            animation: gradient 500ms;
            background: #EFF5FC;
            color: #000;
         }
    }
    button:active {
        
      }
    .ai-template-set{
        .template-head{
            color: #696969;
        }
        .ai-template-set-btn{
            .imgSpan{
                width: fit-content;
                height: fit-content;
            }
        }
        
    }
    .a-template-selection{
        .use-Head{
            color: #787878;
        } 
    }
}