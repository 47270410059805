@import "../../App.scss";

.confirmationConfirm {
  width: 400px;
  min-height: 230px;
  padding-bottom: 12px;
}
h6 {
  padding: 0 12px;
}
#confirmationConfirmBody {
  padding: 0;
  .confirmationYesBtn {
    background-color: #4880ff;
    border-radius: 2.4px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27);
    width: 112px;
    height: 39px;
  }
  .confirmationNoBtn {
    border: 1.2px solid #d4e5f9;
    border-radius: 2.4px;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.27);
    width: 112px;
    height: 39px;
  }
}
