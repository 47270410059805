.bankIntegration{
    .bank-in-searchView {
        #bank-in-searchstyle {
          width: 100%;
        }
        input {
          background-color: #ffffff;
          border: 1px solid #d4e5f9;
          border-radius: 3px;
          width: 67px !important;
          height: 35px;
          color: #535353;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          border-right: none !important;
        }
        input:focus {
          border: 1px solid #d4e5f9 !important;
          border-right: none !important;
          outline: none !important;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
        }
        span {
          border: 1px solid #d4e5f9 !important;
          background-color: #fff !important;
        }
        img {
          width: 14.14px;
          height: 14.13px;
        }
      }
    // .bank-in-actionTh {
    //    // width: 24%;
    //   }
      .addBankBtn {
        color: #fefffe;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
      .bank-in-headText {
        color: #5e5e5e;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
      }   
      #bank-in-rejeBtn{
        color: #ff5660;
    background-color: transparent;
    border: none;
    outline: none;
    outline: 0;
    pointer-events: none;
    text-align: left;
      }
      .bank-in-bodyText {
            color: #000;
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            overflow: auto;
        }
        .bank-in-HeadText {
            color: #5e5e5e;
        
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
          }
          .bank-in-sendBtn:hover {
            background-color: #24ccb8;
            color: #fff;
          }
          .bank-in-rejBtn:hover {
            background-color: #ff5660;
            color: #fff;
          }
  tbody{
    max-height: calc(100vh - 270px) !important;
    min-height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
  }
        
}