@import "../../App.scss";
@import "../../scss/styles.scss";
$grey: #e5e9ed;
.error {
  display: block;
  color: red;
  text-align: left;
  padding: 3px 10px;
  margin: 0px;
  font-size: 12px;
}



.heightscroll {
  max-height: 230px;
  overflow: auto;
}
table {
  .th-width {
    width: 20px;
  }
}
.adLicenceHeadText {
  color: #000000;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}
.inputPopUp {
  font-size: 14.52px !important;
  background: #e5e9ed !important;
  height: 45px !important;
  font-weight: 400;
  border: none !important;
  border-radius: 5px !important;
  font-family: "latoRegular" !important;
  font-weight: 500 !important;
}

#modelHeader {
  border-bottom: none;
  position: relative;
}
.styles_modal__gNwvD {
  border-radius: 10px;
}
.ad-li-maindiv{
  width: 550px;
  height: 555px;
 // height: 475px;
  .ad-li-field{
    position: relative;
    .ad-li-fieldError{
      position: absolute;
      bottom: -20px;
    }
    .selectplanOption{
      background: #e5e9ed;
    }
    .selectplanOption:hover{
      background: crimson;
    }
    .selectplanOption::after{
      background:blue;
    }
  }
  #ad-li-PeriodInput {
    width: 90px !important;
    height: 45px !important;
    outline: none;
    background-color: #e5e9ed !important;
    border: 0.92px solid #D4E5F9 !important;
    border-radius: 2.76px !important;
    margin-right: 7px;
  }
  .ad-li-already{
    position: absolute;
    bottom: -46px;
  }
  #footer {
    background-color: #ffffff00 !important;
    position: absolute;
    top: 390px;
  // top: 300px;
    left: 190px;
  
    button {
      width: 150px;
      height: 39px;
    }
  }
  .ad-li-select{    
    height: 37px !important;
    font-size: 16.67px !important;
    background-color: #e5e9ed !important;
    border: none !important;
    border-radius: 5px !important;
    color: #787e8c !important;
    padding: .375rem 1.75rem .375rem .75rem;
  }
}
