/* body, * {font-family: 'Open Sans', sans-serif;} */
/*****************Icons********************/
@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.login-box{
  width: 25%;
  margin: 0 auto;
}
.login-title{
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
.App{
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #f2f2f2 !important;
  box-sizing: border-box;
  /* font-family: 'helveticaLight', 'Lato', sans-serif !important; */
}