#licenseStatusId{
	.li-st-div{
		border-bottom: 1px solid #D4E5F9;
		button{
			background-color:#fff;
			border: 1px solid #D4E5F9;
			border-radius: 3px;
			width: 112px;
			height: 35px;
			color: #4A494A;
			font-size: 14px;
			font-weight: 600;
			line-height: 17px;
			text-align: center;
		}
	}
	.labelfreePlan{
		color: #4A494A;
		font-weight: 700;
		font-size: 12px;
	}
	.alignment{
		margin-right: 44px;
	}
}