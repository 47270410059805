.setDatePopUpMain{
    width: 370px;
    height: max-content;
    position: relative;
    .set-date-head{

    }
    .set-date-body{
        .set-date-content{
            .set-nurture{
                input{
                    border: none !important;
                    background-color: #E5E9ED;
                    height: 43px;
                }
                .set-datePicket{
                    background-color: #E5E9ED;
                    border-radius: 3px;
                    height: 43px;
                }
            }
        }
        .set-date-action{
            button{
                width: 150px;
            }
        }
    }
}